import { AnyAction } from 'redux';
import { IUserSession } from '@entities/user-session';
import { USER_LOADED, CURRENT_MENY_ITEM, SET_RESTART_SERVICE_REQUIRED } from './actions';

export interface IReduxState {
    userSession?: IUserSession;
    currentMenuItem: string;
}

export const initialState: IReduxState = {
    userSession: undefined,
    currentMenuItem: 'orders',
};

export const userReducer = (state: any, action: AnyAction) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                userSession: action.payload,
            };

        case CURRENT_MENY_ITEM:
            return {
                ...state,
                currentMenuItem: action.payload,
            };

        case SET_RESTART_SERVICE_REQUIRED:
            return {
                ...state,
                restartServiceRequired: action.payload,
            };
        default:
            return state;
    }
};
