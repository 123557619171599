import React from 'react';

import { Space, Button, Tooltip } from 'antd';

import './toolbar.css';

interface IToolbar {
    commands: Array<any>;
    farCommands?: Array<any>;
    style?: any;
}

const Toolbar = (props: IToolbar) => {
    return (
        <div className='commands-container' style={props.style}>
            <Space>
                {props.commands.map((item: any) => {
                    return (
                        <Tooltip key={item.key} placement='top' title={item.label}>
                            <Button
                                disabled={item.disabled}
                                type={item.type}
                                icon={item.icon}
                                style={item.style}
                                onClick={item.onClick}
                                size='middle'
                            >
                                {item.showLabel && item.label}
                            </Button>
                        </Tooltip>
                    );
                })}
            </Space>

            <Space>
                {props.farCommands?.map((item: any) => {
                    return (
                        <Tooltip key={item.key} placement='left' title={item.label}>
                            <Button
                                disabled={item.disabled}
                                type={item.type}
                                icon={item.icon}
                                style={item.style}
                                onClick={item.onClick}
                                size='middle'
                            ></Button>
                        </Tooltip>
                    );
                })}
            </Space>
        </div>
    );
};

export default Toolbar;
