export enum TransactionType {
    Depositing = 1,
    Debiting = 2,
    Refund = 3,
    Transfer = 4,
}

export const enumLabel = (value?: TransactionType) => {
    switch (value) {
        case TransactionType.Depositing:
            return 'Пополнение';
        case TransactionType.Debiting:
            return 'Списание по счету';
        case TransactionType.Refund:
            return 'Возврат';
        case TransactionType.Transfer:
            return 'Между счетами';
        default:
            return '';
    }
};
