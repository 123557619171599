import React, { useState, useEffect } from 'react';

import { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';

import { Table, Button, Input, DatePicker, Tooltip, Form, Switch, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    EyeFilled,
    PaperClipOutlined,
    FilterFilled,
    CheckOutlined,
    FileExcelOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Bill from '@components/bills/bill';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { delayAction, dataURItoBlob, toFinanceString } from '@extensions/utils';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';

import { IBillFilter } from '@src/core/entities/bill-filter';
import { IBill } from '@src/core/entities/bill';

import { PenIcon } from '@src/core/icons';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBillHeader {
    loadingOn?: Dayjs;
    totalPriceFinal: number;
    totalPriceFinalRub: number;
    bills: Array<IBill>;
}

const BillHeaders = () => {
    var Buffer = require('buffer/').Buffer;

    const { RangePicker } = DatePicker;

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [filter, setFilter] = useState<IBillFilter>({
        userId: userSession.userId,
        completedOnly: true,
        isPaid: false,
        isArchived: false,
        includeDocuments: true,
    });

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [headers, setHeaders] = useState<Array<IBillHeader>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openBill, setOpenBill] = useState<boolean>(false);
    const [bills, setBills] = useState<Array<IBill>>([]);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('bills/headers', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setHeaders(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (bills && bills.length > 0) {
            setOpenBill(true);
        }
    }, [bills]);

    const onDownload = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                var fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }
            }

            var fileURL = URL.createObjectURL(fileObj);
            window.open(fileURL);
        }
    };

    const onExport = () => {
        serverFetch('bills/export', { method: 'GET', queryParams: filter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='number'
                        placeholder='Номер счета'
                        value={filter.number}
                        onChange={(data) => {
                            setFilter({ ...filter, number: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter.consigneeCode}
                        onChange={(data) => {
                            setFilter({ ...filter, consigneeCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter.markingCode}
                        onChange={(data) => {
                            setFilter({ ...filter, markingCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter.awbNumber}
                        onChange={(data) => {
                            setFilter({ ...filter, awbNumber: data.target.value });
                        }}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='loading'
                        format='DD.MM.YYYY'
                        placeholder={['Загрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={filter.loadingFrom && filter.loadingTo && [filter.loadingFrom, filter.loadingTo]}
                        onChange={(value) => {
                            if (!value) {
                                setFilter({
                                    ...filter,
                                    loadingFrom: undefined,
                                    loadingTo: undefined,
                                });

                                return;
                            }

                            if (value[0]) {
                                setFilter({
                                    ...filter,
                                    loadingFrom: dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                });
                            }

                            if (value[1]) {
                                setFilter({
                                    ...filter,
                                    loadingTo: dayjs(value[1]).utc(true).set('hour', 23).set('minute', 59).set('second', 59),
                                });
                            }
                        }}
                    />,
                    <Form.Item key='isActiveOnly' label='Оплаченные' style={{ margin: 0 }}>
                        <Switch
                            checked={filter.isPaid}
                            onChange={(value: boolean) => {
                                setFilter({ ...filter, isPaid: value });
                            }}
                        />
                    </Form.Item>,
                ]}
                onClear={() =>
                    setFilter({
                        userId: userSession.userId,
                        completedOnly: true,
                        isPaid: false,
                        isArchived: false,
                        includeDocuments: true,
                    })
                }
            />
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const expandedBills = (record: IBillHeader) => {
        const columns: ColumnsType<IBill> = [
            {
                title: ' ',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return <Button type='link' icon={<EyeFilled />} onClick={() => setBills([record])} />;
                },
            },
            {
                title: '#',
                align: 'center',
                dataIndex: 'number',
                width: 80,
            },
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: ' ',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return (
                        record.isChanged && (
                            <Tooltip title={`Счет изменен ${dayjs.utc(record.changedOn).local().format('DD.MM.YYYY')}`}>
                                <PenIcon />
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 180,
            },
            {
                title: 'Поставщик',
                width: 130,
                dataIndex: 'contractorName',
            },
            {
                title: 'Расчетный город',
                dataIndex: 'cityName',
                width: 180,
            },
            {
                title: 'Торговая площадка',
                dataIndex: 'tradingPlatform',
                width: 160,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 130,
            },
            {
                title: 'AWB',
                width: 250,
                render: (_, record) => {
                    return record.awbNumber && <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{record.awbNumber}</span>;
                },
            },
            {
                title: 'Счет ($)',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return (
                        <>
                            <span>
                                {record.totalPriceFinal
                                    ? toFinanceString(record.totalPriceFinal, 2)
                                    : toFinanceString(record.totalPrice, 2)}
                            </span>
                            {record.isPaid && (
                                <Tooltip title='Оплачен' color='green'>
                                    <CheckOutlined style={{ fontSize: 18, color: 'green', marginLeft: 5 }} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Счет (₽)',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return record.totalPriceFinal
                        ? toFinanceString(record.totalPriceFinalRub, 2)
                        : toFinanceString(record.totalPriceRub, 2);
                },
            },
            {
                title: 'Документы',
                render: (_, record) => {
                    return (
                        record.documents && (
                            <>
                                {record.documents.map((d) => {
                                    return (
                                        <div key={d.uid}>
                                            <Button icon={<PaperClipOutlined />} type='link' onClick={() => onDownload(d)}>
                                                {d.name}
                                            </Button>
                                        </div>
                                    );
                                })}
                            </>
                        )
                    );
                },
            },
        ];

        return <Table rowKey='id' size='small' columns={columns} dataSource={record.bills} pagination={false} />;
    };

    const renderTable = () => {
        const columns: ColumnsType<IBillHeader> = [
            {
                title: 'Отгрузка',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
            },
            {
                width: 630,
            },
            {
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return <Button type='link' icon={<EyeFilled />} onClick={() => setBills(record.bills)} />;
                },
            },
            {
                title: 'Общий Счет ($)',
                align: 'center',
                width: 140,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return toFinanceString(record.totalPriceFinal, 2);
                },
            },
            {
                title: 'Общий Счет (₽)',
                align: 'center',
                width: 140,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.totalPriceFinalRub ? toFinanceString(record.totalPriceFinalRub, 2) : '-';
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='loadingOn'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedBills }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {openBill && (
                <Bill
                    bills={bills}
                    onClose={() => {
                        setBills([]);
                        setOpenBill(false);
                    }}
                />
            )}

            {notificationContextHolder}
        </>
    );
};

export default BillHeaders;
