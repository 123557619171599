export enum Currency {
    Rub = 1,
    Usd = 2,
    Eur = 3,
}

export const enumLabel = (value: Currency) => {
    switch (value) {
        case Currency.Rub:
            return 'RUB';
        case Currency.Usd:
            return 'USD';
        case Currency.Eur:
            return 'EUR';

        default:
            return '';
    }
};
