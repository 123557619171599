import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input, InputNumber, Form, Space, notification, Divider, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { IConsignee } from '@entities/consignee';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';

import { UserType } from '@enums/user-type';

interface IAccountParams {
    userId: string | undefined;
    newPassword: string | undefined;
}

const UserAccount = () => {
    const { TextArea } = Input;

    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IUser>();
    const [loading, setLoading] = useState<boolean>(false);
    const [allowSetPassword, setAllowSetPassword] = useState(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        setLoading(true);

        serverFetch(`users/account`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения учетной записи', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onFinishSave = () => {
        if (!entity) return;

        setLoading(true);

        var account: IAccountParams = { userId: entity.id, newPassword: entity.newPassword };
        serverFetch(`users/account`, { method: 'PUT', bodyData: account })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения учетной записи', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignee> = [
            {
                title: 'ID',
                align: 'center',
                dataIndex: 'code',
                width: 70,
            },
            {
                title: 'Маркировка',
                width: 300,
                dataIndex: 'allMarkings',
            },
            {
                title: 'Город доставки',
                width: 150,
                dataIndex: 'cityName',
            },
            {
                title: 'ФИО',
                width: 250,
                dataIndex: 'fullName',
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                title: 'Город',
                width: 150,
                dataIndex: 'legalCityName',
            },
            {
                title: 'Адрес',
                width: 350,
                dataIndex: 'address',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entity?.consignees}
                pagination={false}
                scroll={{ y: `calc(100vh - 505px)` }}
            />
        );
    };

    return (
        <>
            <FormHeader title={`Личный кабинет`} />
            {!loading && entity && (
                <>
                    <Form
                        colon={false}
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 8 }}
                        style={{ marginBottom: 40 }}
                        onFinish={onFinishSave}
                    >
                        <Form.Item initialValue={entity?.login} label='Логин' name='code'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label='Пароль' rules={[{ required: true, message: 'Укажите пароль' }]}>
                            <Space.Compact style={{ width: '100%' }}>
                                <Input.Password
                                    value={entity.newPassword}
                                    disabled={!allowSetPassword}
                                    placeholder={!allowSetPassword ? '*******' : ''}
                                    onChange={(data) => {
                                        setEntity({ ...entity, newPassword: data.target.value });
                                    }}
                                />
                                <Button
                                    disabled={entity.isDemo}
                                    type='primary'
                                    onClick={() => {
                                        setAllowSetPassword(true);
                                    }}
                                >
                                    Сменить пароль
                                </Button>
                            </Space.Compact>
                        </Form.Item>
                        <Form.Item initialValue={entity?.fullName} label='ФИО' name='fullName'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label='Телефон'>
                            <MaskedInput value={entity?.phone} disabled={true} size='middle' mask={'+7 (000) 000-00-00'} />
                        </Form.Item>
                        <Form.Item initialValue={entity?.email} label='Email' name='email'>
                            <Input disabled={true} />
                        </Form.Item>

                        {entity.newPassword && (
                            <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                                <Space size={'small'} style={{ float: 'right', marginTop: 20 }}>
                                    <Button
                                        type='link'
                                        onClick={() => {
                                            setEntity({ ...entity, newPassword: undefined });
                                            setAllowSetPassword(false);
                                        }}
                                    >
                                        Отменить
                                    </Button>
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        Сохранить
                                    </Button>
                                </Space>
                            </Form.Item>
                        )}
                    </Form>
                    {userSession.type == UserType.Supplier ? (
                        <>
                            <Divider orientation='left'>Грузополучатели</Divider>
                            {renderTable()}
                        </>
                    ) : (
                        entity.consignees?.map((c) => {
                            return (
                                <>
                                    <Divider orientation='left'>Грузополучатель</Divider>

                                    <Form
                                        key={c.id}
                                        colon={false}
                                        labelCol={{ span: 3 }}
                                        wrapperCol={{ span: 8 }}
                                        style={{ marginBottom: 40 }}
                                        onFinish={onFinishSave}
                                    >
                                        <Form.Item initialValue={c.code} label='ID' name='code'>
                                            <InputNumber disabled={true} />
                                        </Form.Item>
                                        <Form.Item initialValue={c.allMarkings} label='Маркировка' name='allMarkings'>
                                            <TextArea disabled={true} rows={3} />
                                        </Form.Item>
                                        <Form.Item initialValue={c.cityName} label='Город доставки' name='city'>
                                            <Input disabled={true} />
                                        </Form.Item>
                                        <Form.Item initialValue={c.fullName} required label='ФИО' name='fullName'>
                                            <Input disabled={true} />
                                        </Form.Item>
                                        <Form.Item label='Телефон'>
                                            <MaskedInput value={c.phone} disabled={true} size='middle' mask={'+7 (000) 000-00-00'} />
                                        </Form.Item>
                                        <Form.Item initialValue={c.cityName} label='Город' name='cityName'>
                                            <Input disabled={true} />
                                        </Form.Item>
                                        <Form.Item initialValue={c.address} label='Адрес' name='address'>
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </Form>
                                </>
                            );
                        })
                    )}

                    {/*    {entity.consignees?.map((c) => {
                        return (
                            <>
                                <Form
                                    key={c.id}
                                    colon={false}
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 8 }}
                                    style={{ marginBottom: 40 }}
                                    onFinish={onFinishSave}
                                >
                                    <Form.Item initialValue={c.code} label='ID' name='code'>
                                        <InputNumber disabled={true} />
                                    </Form.Item>
                                    <Form.Item initialValue={c.allMarkings} label='Маркировка' name='allMarkings'>
                                        <TextArea disabled={true} rows={3} />
                                    </Form.Item>
                                    <Form.Item initialValue={c.cityName} label='Город' name='city'>
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <Form.Item initialValue={c.address} label='Адрес' name='address'>
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <Form.Item initialValue={c.fullName} required label='ФИО' name='fullName'>
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <Form.Item label='Телефон'>
                                        <MaskedInput value={c.phone} disabled={true} size='middle' mask={'+7 (000) 000-00-00'} />
                                    </Form.Item>
                                </Form>

                                <Divider />
                            </>
                        );
                    })} */}
                </>
            )}

            {contextHolder}
        </>
    );
};

export default UserAccount;
