import React, { useState, useEffect } from 'react';

import { Dayjs } from 'dayjs';

import { Table, Tooltip, Tag, Select, DatePicker, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, MinusOutlined, PlusOutlined, UndoOutlined, FilterFilled, SwapOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';
import { delayAction, toFinanceString, getEnumList } from '@extensions/utils';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { ITransaction } from '@entities/transaction';
import { ITransactionFilter } from '@entities/transaction-filter';

import { Currency } from '@enums/currency';
import { TransactionType, enumLabel as TransactionTypeLabel } from '@enums/transaction-types';
import { IEnumItem } from '@enums/enum-item';
import { OperationType, enumLabel as OperationTypeLabel } from '@enums/operation-type';

import { serverFetch } from '@src/core/server';

import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITransactionsControl {
    userId: string;
    hideInitiator?: boolean;
}

const Transactions = (props: ITransactionsControl) => {
    const { RangePicker } = DatePicker;

    const { userId, hideInitiator } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [transactions, setTransactions] = useState<Array<ITransaction>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [filter, setFilter] = useState<ITransactionFilter>({
        userId: userId,
        createdFrom: dayjs().subtract(2, 'month').utc(true).set('hour', 0).set('minute', 0).set('second', 0),
    });

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [transactionTypes] = useState<Array<IEnumItem>>(getEnumList(TransactionType, TransactionTypeLabel));
    const [operationTypes] = useState<Array<IEnumItem>>(getEnumList(OperationType, OperationTypeLabel));

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setTransactions([]);

        setLoading(true);

        serverFetch('transactions', { method: 'GET', queryParams: filter })
            .then((data) => {
                setTransactions(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Select
                        key='transactionType'
                        placeholder='Тип операции'
                        value={filter?.type}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value: any) => setFilter({ ...filter, type: value })}
                        options={transactionTypes.map((t) => {
                            return { value: t.value, label: t.label };
                        })}
                    />,
                    <Select
                        key='operationType'
                        placeholder='Тип платежа'
                        value={filter?.operationType}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 160 }}
                        onChange={(value: any) => setFilter({ ...filter, operationType: value })}
                        options={operationTypes.map((t) => {
                            return { value: t.value, label: t.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 230 }}
                        allowEmpty={[true, true]}
                        key='date'
                        format='DD.MM.YYYY'
                        placeholder={['Операция с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={(filter.createdFrom || filter.createdTo) && [filter.createdFrom, filter.createdTo]}
                        onChange={(value) => {
                            if (!value) {
                                setFilter({
                                    ...filter,
                                    createdFrom: undefined,
                                    createdTo: undefined,
                                });

                                return;
                            }

                            setFilter({
                                ...filter,
                                createdFrom:
                                    value && value[0]
                                        ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0)
                                        : undefined,
                                createdTo:
                                    value && value[1]
                                        ? dayjs(value[1]).utc(true).set('hour', 23).set('minute', 59).set('second', 59)
                                        : undefined,
                            });
                        }}
                    />,
                ]}
                onClear={() => setFilter({ userId: userId })}
            />
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ITransaction> = [
            {
                title: 'Счет',
                width: 60,
                align: 'center',
                render: (_, record) => {
                    return record.currency == Currency.Rub ? (
                        <RubleFilledIcon style={{ fontSize: 20 }} />
                    ) : record.currency == Currency.Usd ? (
                        <UsdFilledIcon style={{ fontSize: 20 }} />
                    ) : (
                        <EuroFilledIcon style={{ fontSize: 20 }} />
                    );
                },
            },
            {
                title: 'Операция',
                width: 150,
                render: (_, record) => {
                    let icon = <></>;
                    let color = '';
                    let operation = <></>;

                    if (record.operationType == OperationType.Cash) {
                        operation = (
                            <Tag color='green' style={{ color: '#000000' }}>
                                Наличный
                            </Tag>
                        );
                    } else if (record.operationType == OperationType.NonCash) {
                        operation = (
                            <Tag color='var(--primary-color)' style={{ color: '#000000' }}>
                                Безналичный
                            </Tag>
                        );
                    }

                    if (record.type == TransactionType.Debiting) {
                        color = 'red';
                        icon = <MinusOutlined style={{ color: color }} />;
                    } else if (record.type == TransactionType.Refund) {
                        color = 'purple';
                        icon = <UndoOutlined style={{ color: color }} />;
                    } else if (record.type == TransactionType.Depositing) {
                        color = 'green';
                        icon = <PlusOutlined style={{ color: color }} />;
                    } else if (record.type == TransactionType.Transfer) {
                        color = 'blue';
                        icon = <SwapOutlined style={{ color: color }} />;
                    }

                    return (
                        <>
                            {icon}
                            <span style={{ color: color, fontWeight: 600, marginLeft: 5 }}>
                                {toFinanceString(record.currency == Currency.Usd ? record.ammount : record.ammount, 2)}
                            </span>
                            <div>{operation}</div>
                        </>
                    );
                },
            },
            {
                title: 'Комиссия %',
                align: 'center',
                width: 110,
                render: (_, record) => {
                    return record.commission && record.commission.toFixed(2);
                },
            },
            {
                title: 'Дата',
                align: 'center',
                width: 140,
                render: (_, record) => {
                    return dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: 'Инициатор',
                dataIndex: 'createdByName',
                width: 250,
                hidden: hideInitiator,
            },
            {
                title: 'Детали',
                render: (_, record) => {
                    return (
                        <div>
                            {record.comment && <span style={{ fontWeight: 600 }}>{record.comment}</span>}

                            {record.type == TransactionType.Depositing ? (
                                <span style={{ marginLeft: 10, fontWeight: 600 }}>Внесение</span>
                            ) : record.type == TransactionType.Debiting ? (
                                <span style={{ marginLeft: 10, fontWeight: 600 }}>Списание по счету #{record.billNumber}</span>
                            ) : record.type == TransactionType.Refund ? (
                                <span style={{ marginLeft: 10, fontWeight: 600 }}>Возврат</span>
                            ) : (
                                <></>
                            )}

                            {/* else if (record.type == TransactionType.Depositing && record.currencyRate) {
                        return (
                            <span style={{ marginLeft: 10, fontWeight: 600 }}>
                                внесение {toFinanceString(record.ammount, 2)} (₽) по курсу {toFinanceString(record.currencyRate, 4)} (₽)
                            </span>
                        );
                    } */}
                        </div>
                    );
                },
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={transactions}
                loading={tableLoading}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? 245 : 187}px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default Transactions;
